<template>
    <div>
        <form v-on:submit.prevent="login">
            <div class="text-2xl uppercase mb-4 font-medium">Login</div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">E-Mail-Adresse</label>
                <InputText
                    class="w-full"
                    type="email"
                    required
                    v-model="email"
                />
                <small class="p-invalid block mt-2" v-if="error === true">
                    Für diese Daten wurde kein Benutzer gefunden.
                </small>
            </div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    Passwort
                </label>
                <InputText
                    class="w-full"
                    type="password"
                    required
                    v-model="password"
                />
            </div>
            <Button label="Login" class="w-full" type="submit" />
        </form>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import auth from './../../plugins/auth';

    export default {
        data() {
            return {
                email: '',
                password: '',
                error: null,
            }
        },
        components: {
            Button,
            InputText,
        },
        methods: {
            login() {
                this.error = false;
                this.formErrors = {};
                this.$store.commit('incrementLoadingIndex');
                this.$http.post('auth', {email: this.email, password: this.password}).then(response => {
                    auth.setAuth(response.data.token);
                    auth.setRefreshToken(response.data.refresh_token);
                    this.$store.commit('decrementLoadingIndex');
                    this.$router.push('/dashboard');
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Erfolgreich',
                        detail: 'Sie haben sich erfolgreich eingeloggt.',
                        life: 3000
                    });
                }, () => {
                    this.error = true;
                    this.$store.commit('decrementLoadingIndex');
                    this.formErrors = {
                        email: [
                            "Kein Benutzer gefunden oder Passwort falsch"
                        ]
                    };
                });
            },
        }
    }
</script>
